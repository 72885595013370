import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-iframe',
  templateUrl: './pay-iframe.component.html',
  styleUrls: ['./pay-iframe.component.scss']
})
export class PayIframeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
