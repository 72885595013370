export class API_Constants {
 // public static readonly baseURL = 'https://localhost:44338/';

  //PRODUCTION
  //public static readonly baseURL = 'https://intelpeek-payments-app.azurewebsites.net/';

  //UAT
  //public static readonly baseURL = 'https://intelpeek-payments-app-uat.azurewebsites.net/';

  //DEV

  //Leave it empty so that it calls the host URL only
  public static readonly baseURL = '';

  public static readonly getPaymentDetails = 'api/Payment/GetPaymentDetails';
  public static readonly getPaymentDetailsForAdHoc = 'api/Payment/GetPaymentDetailsAdHoc/';
}
