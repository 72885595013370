<div class="container-fluid">
  <div class="row">
    <h3 class="text-center w-100 m-2">Order Details</h3>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Product</th>
            <th scope="col">Agent</th>
            <th scope="col">Provider</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i = index">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ item.product }}</td>
            <td>{{ item.agent }}</td>
            <td>{{ item.provider }}</td>
            <td>{{ currency }} {{ item.amount }}</td>
          </tr>
          <tr>
            <th class="text-right" scope="row" colspan="4">
              Total to be paid:
            </th>
            <td>{{ currency }} {{ totalAmount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
