<div class="container-fluid">
  <!-- <div class="row"> -->
  <h3 class="text-center w-100 m-2">Order Details</h3>

  <p class="text-left">
    <span class="font-weight-bolder">Customer Name: </span>{{customer_Name}}
  </p>
  <p class="text-left"><span class="font-weight-bolder">Customer Email: </span>{{customer_Email}}</p>
  <!-- <p><span class="font-weight-bolder">Application Number:</span>{{application_Number}}</p> -->
  <p class="text-left"><span class="font-weight-bolder">Order Description: </span>{{description}}</p>
  <p class="text-left"><span class="font-weight-bolder">Amount Due: </span>{{amount}}</p>

  <!-- </div> -->
</div>
