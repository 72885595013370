<div class="container-fluid">
  <div class="row">
    <div class="text-center mt-5">
      <mat-card class="mx-auto w-75">
        <div class="row">
          <div class="col-12 col-md-7" *blockUI="'orderDetailsLoading'">
           <app-order-description [description]="orderDescription" [customer_Name]="customer_Name" [customer_Email]="customer_Email" [amount]="amount"></app-order-description>
          </div>
          <div class="col-12 col-md-5">
            <app-checkout-form
              [otherInputControls]="otherInputControls"
              [checkout_URL] = "checkout_URL"
            ></app-checkout-form>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
