<div class="container-fluid">
  <div class="row">
    <div class="col-12 mt-5 text-center">
      <h2>Your payment has been successfully completed!</h2>
      <mat-card class="my-4 w-25 mx-auto">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Payment Reference</th>
                <th scope="col">Amount Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ PaymentReference }}</td>
                <td>{{ Currency }} {{ Amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <!--<mat-card class="my-4 w-75 d-md-none d-sm-block mx-auto">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Payment Reference</th>
                <th scope="col">Amount Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ PaymentReference }}</td>
                <td>{{ Currency }} {{ Amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>-->

      <!--<button mat-raised-button class="text-white" style="background: #5ec8d5">
    Download Receipt
  </button>-->
    </div>
  </div>
</div>
