import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.scss']
})
export class OrderDescriptionComponent implements OnInit {
  @Input() description: string;
  @Input() customer_Name: string;
  @Input() application_Number: string;
  @Input() customer_Email: string;
  @Input() amount: string;
  constructor() { }

  ngOnInit() {
  }

}
