import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  ErrorMessage: string;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const state = {
        errorCode: params['errorCode'],
        errorMessage: params['errorMessage'],
      };
      this.ErrorMessage = state.errorCode + ' ' + state.errorMessage;
    });
  }
}
