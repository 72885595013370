import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TokenizationRequest } from '../models/tokenization_request';
import { environment } from 'src/environments/environment';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss'],
})
export class CheckoutFormComponent implements OnInit {
  enableExpiry: boolean = true;
  selectedDate = moment().format('MM / YYYY');
  minDate = moment();

  checkoutForm = this.fb.group({
    card_number: ['', Validators.required],
    card_security_code: ['', Validators.required],
    card_holder_name: ['', Validators.required],
    expiry_date: [null, Validators.required],
    merchant_identifier: [''],
    merchant_reference: [''],
    return_url: [''],
    language: [''],
    access_code: [''],
    service_command: [''],
    signature: [''],
    token_name: [''],
    remember_me: [false]
  });

  //Array of inputs received from API for tokenization request like calculated signature, command, customer name, email etc
  @Input() otherInputControls: TokenizationRequest;
  @Input() checkout_URL: string;
  @Input() subsequent_payment_mode: number;
  @ViewChild('form') form: ElementRef;
  @ViewChild('dp') datePicker: MatDatepicker<Moment>;
  @BlockUI() blockUI: NgBlockUI;
  // navigationExtras: NavigationExtras = {
  //   state: {
  //     errorCode: '',
  //     errorMessage: '',
  //   },
  // };

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
  }

  onSubmit() {
    this.checkoutForm.markAllAsTouched();
    if (this.checkoutForm.valid) {
      this.blockUI.start();
      let cardNumber = this.checkoutForm.get('card_number').value;

      //Remove spaces from card number
      this.checkoutForm
        .get('card_number')
        .setValue(cardNumber.replace(/\s/g, ''));
      let expiryDate = this.checkoutForm.get('expiry_date').value;
      //Format moment date as YYMM as required by payfort
      const formattedExpiryDate = moment(expiryDate, 'MM / YYYY').format(
        'YYMM'
      );
      this.selectedDate = formattedExpiryDate;
      this.checkoutForm.get('expiry_date').setValue(formattedExpiryDate);
      this.form.nativeElement.submit();
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.checkoutForm.get('expiry_date').value;
    ctrlValue.year(normalizedYear.year());
    // const formattedExpiryDate = ctrlValue.format('YYMM');
    // this.checkoutForm.get('expiry_date').setValue(formattedExpiryDate);
    this.checkoutForm.get('expiry_date').setValue(ctrlValue);
    this.selectedDate = ctrlValue.format('MM / YYYY');
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.checkoutForm.get('expiry_date').value;
    ctrlValue.month(normalizedMonth.month());
    //Format moment date as YYMM as required by payfort
    // const formattedExpiryDate = ctrlValue.format('YYMM');
    // this.checkoutForm.get('expiry_date').setValue(formattedExpiryDate);
    this.checkoutForm.get('expiry_date').setValue(ctrlValue);
    this.selectedDate = ctrlValue.format('MM / YYYY');
    datepicker.close();
  }

  toFormattedDate(dateString) {
    const date = new Date(dateString);
    console.log(date);
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  formatExpiryDate(event) {
    if (event.target.value.length == 2) {
      let formattedExpiryDate = event.target.value + ' / ';
      this.checkoutForm.get('expiry_date').setValue(formattedExpiryDate);
    }
  }
}
