import { HeaderComponent } from './common/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PayComponent } from './pay/pay.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PayIframeComponent } from './pay-iframe/pay-iframe.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ErrorComponent } from './error/error.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { MatDividerModule } from '@angular/material/divider';
import { CheckoutFormComponent } from './checkout-form/checkout-form.component';
import { HttpClientModule } from '@angular/common/http';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { PayAdhocComponent } from './pay-adhoc/pay-adhoc.component';
import { OrderDescriptionComponent } from './order-description/order-description.component';
import { BlockUIModule } from 'ng-block-ui';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYMM',
  },
  display: {
    dateInput: 'MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [		
    AppComponent,
    PayComponent,
    PayIframeComponent,
    OrderDetailsComponent,
    ErrorComponent,
    ThankYouComponent,
    CheckoutFormComponent,
    HeaderComponent,
      PayAdhocComponent,
      OrderDescriptionComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    MatDividerModule,
    HttpClientModule,
    MatMomentDateModule,
    BlockUIModule.forRoot()
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
