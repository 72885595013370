<div class="container-fluid">
  <div class="row">
    <div class="col-12 my-auto">
      <form
        [action]="checkout_URL"
        method="POST"
        (ngSubmit)="onSubmit()"
        [formGroup]="checkoutForm"
        #form
      >
        <mat-card class="ml-auto mr-auto text-center">
          <!-- Card Number -->
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Card Number</mat-label>
            <input matInput
                   name="card_number"
                   formControlName="card_number"
                   inputmode="tel"
                   autocomplete="cc-number"
                   ccNumber
                   maxlength="19" />
            <mat-error *ngIf="
                checkoutForm.get('card_number').invalid &&
                (checkoutForm.get('card_number').touched ||
                  checkoutForm.get('card_number').dirty) &&
                checkoutForm.get('card_number').errors.required
              ">
              Card Number is required
            </mat-error>
            <mat-error *ngIf="
                checkoutForm.get('card_number').invalid &&
                (checkoutForm.get('card_number').touched ||
                  checkoutForm.get('card_number').dirty) &&
                (checkoutForm.get('card_number').errors.min ||
                  checkoutForm.get('card_number').errors.max)
              ">
              Card number should be 16 digits
            </mat-error>
            <!-- <mat-icon matSuffix>credit_card</mat-icon> -->
          </mat-form-field>
          <!-- Card Holder Name -->
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Card Holder Name</mat-label>
            <input matInput
                   name="card_holder_name"
                   formControlName="card_holder_name" />
            <mat-error *ngIf="
                checkoutForm.get('card_holder_name').invalid &&
                (checkoutForm.get('card_holder_name').touched ||
                  checkoutForm.get('card_holder_name').dirty) &&
                checkoutForm.get('card_holder_name').errors.required
              ">
              Card Holder Name is required
            </mat-error>
          </mat-form-field>
          <!-- Expiry Date -->
          <mat-form-field appearance="outline"
                          class="col-12"
                          *ngIf="enableExpiry">
            <mat-label>Expiry Date</mat-label>
            <input matInput
                   name="expiry_date"
                   placeholder="MM / YYYY"
                   formControlName="expiry_date"
                   (keyup)="formatExpiryDate($event)"
                   maxlength="9" />
            <!-- <input
      type="hidden"
      [matDatepicker]="dp"
      formControlName="expiry_date"
      [min]="minDate"
      (dateChange)="dateInput.value = toFormattedDate($event.value)"
    />
    <input
      matInput
      name="expiry_date"
      #dateInput
      [value]="selectedDate"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)"
      panelClass="example-month-picker"
    >
    </mat-datepicker> -->
            <mat-error *ngIf="
                checkoutForm.get('expiry_date').invalid &&
                (checkoutForm.get('expiry_date').touched ||
                  checkoutForm.get('expiry_date').dirty) &&
                checkoutForm.get('expiry_date').errors.required
              "
                       required>
              Expiry Date is required
            </mat-error>
          </mat-form-field>

          <!-- CVV -->
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>CVV/CVC</mat-label>
            <input matInput
                   name="card_security_code"
                   formControlName="card_security_code"
                   type="password"
                   inputmode="tel"
                   autocomplete="off"
                   ccCVC
                   maxlength="3" />
            <mat-error *ngIf="
                checkoutForm.get('card_security_code').invalid &&
                (checkoutForm.get('card_security_code').touched ||
                  checkoutForm.get('card_security_code').dirty) &&
                checkoutForm.get('card_security_code').errors.required
              ">
              CVV/CVC is required
            </mat-error>
          </mat-form-field>

          <!--<p class="font-weight-lighter" *ngIf="otherInputControls?.remember_me=='YES'">Your Card will be saved for future transactions as the selected subsequent payment mode is online</p>-->
          <mat-checkbox name="remember_me"
                        formControlName="remember_me" value="YES" *ngIf="subsequent_payment_mode==610900000">
            <span class="text-wrap text-left">
              I authorize Takaful Emarat to save my card details and deduct future contributions as per my mode of payment.
            </span>
          </mat-checkbox>
          <!--<mat-error *ngIf="
        checkoutForm.get('remember_me').invalid &&
        (checkoutForm.get('remember_me').touched ||
          checkoutForm.get('remember_me').dirty)  &&
        checkoutForm.get('remember_me').errors.required
      ">
    You must agree to the terms to proceed
  </mat-error>-->
          <!-- Generate input controls from JSON received from API -->
          <input hidden
                 type="text"
                 *ngFor="let item of otherInputControls | keyvalue"
                 name="{{ item.key }}"
                 value="{{ item.value }}" />

          <button mat-raised-button
                  type="submit"
                  class="text-white"
                  style="background: #5ec8d5">
            Pay Now
          </button>
        </mat-card>
        
      </form>
    </div>
  </div>
</div>
