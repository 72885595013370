import { PayAdhocComponent } from './pay-adhoc/pay-adhoc.component';
import { ErrorComponent } from './error/error.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PayComponent } from './pay/pay.component';

const routes: Routes = [
  {
    path: '',
    component: PayComponent,
  },
  {
    path: 'pay',
    component:PayComponent
  },
  {
    path:'pay-adhoc',
    component:PayAdhocComponent
  },
  { path: 'thankyou', component: ThankYouComponent },
  { path: 'error', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
