<div class="container-fluid">
  <div class="row">
    <div class="text-center mt-5">
      <mat-card class="mx-auto w-75">
        <div class="row">
          <div class="col-12 col-md-7" *blockUI="'orderDetailsLoading'">
            <app-order-details
              [items]="order_items"
              [totalAmount]="amount"
              [currency]="currency"
            ></app-order-details>
          </div>
          <div class="col-12 col-md-5">
            <app-checkout-form
              [otherInputControls]="otherInputControls"
              [checkout_URL] = "checkout_URL"
              [subsequent_payment_mode] = "subsequent_payment_mode"
            ></app-checkout-form>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
