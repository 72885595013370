import { OrderItem } from './../models/order_item';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  @Input() items: OrderItem[];
  @Input() totalAmount: number = 0;
  @Input() currency: string;
  constructor() {}

  ngOnInit() {}
}
