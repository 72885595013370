import { API_Constants } from './../common/API_Constants';
import { PaymentDetailsRequest } from './../models/payment_details_request';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenizationRequestViewModel } from '../models/tokenization_request_viewmodel';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) {}

  getPaymentDetailsForAdhoc( paymentId: string
  ): Observable<TokenizationRequestViewModel> {
    const url = API_Constants.baseURL + API_Constants.getPaymentDetailsForAdHoc + paymentId;
    return this.httpClient.get<TokenizationRequestViewModel>(
      url,
    );
  }

  getPaymentDetails(
    referenceNumber: string,
    amount: number,
    entityType: string,
    paymentId: string
  ): Observable<TokenizationRequestViewModel> {
    const url = API_Constants.baseURL + API_Constants.getPaymentDetails;
    const body: PaymentDetailsRequest = {
      referenceNumber: referenceNumber,
      amount: Number(amount),
      entityType: entityType,
      paymentId: paymentId,
    };
    return this.httpClient.post<TokenizationRequestViewModel>(
      url,
      body,
      this.httpOptions
    );
  }
}
