import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TokenizationRequest } from '../models/tokenization_request';
import { TokenizationRequestViewModel } from '../models/tokenization_request_viewmodel';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-pay-adhoc',
  templateUrl: './pay-adhoc.component.html',
  styleUrls: ['./pay-adhoc.component.scss']
})
export class PayAdhocComponent implements OnInit {
  referenceNumber: string;
  amount: number;
  entityType: string;
  paymentId: string;
  orderDescription: string;
  customer_Name: string;
  application_Number: string;
  customer_Email: string;
  checkout_URL:string;
  
  // Array of inputs received from API for tokenization request like calculated signature, command, customer name, email etc
  otherInputControls: TokenizationRequest;

  @BlockUI('orderDetailsLoading') orderDetailsLoading:NgBlockUI;
  constructor( private route: ActivatedRoute,
               private router: Router,
               private paymentService: PaymentService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.paymentId = params.paymentId;
      if (!this.paymentId) {
        this.router.navigate(['/error'], {
          queryParams: {
            errorCode: '04',
            errorMessage: 'Payment Id Missing',
          },
        });
      } else {
        this.orderDetailsLoading.start();
        this.paymentService
          .getPaymentDetailsForAdhoc(
            this.paymentId
          )
          .subscribe(
            (tokenizationVM: TokenizationRequestViewModel) => {
              this.orderDetailsLoading.stop();
              this.otherInputControls = tokenizationVM.tokenizationRequest;
              this.orderDescription = tokenizationVM.description;
               this.customer_Email = tokenizationVM.customer_Email;
               this.customer_Name = tokenizationVM.customer_Name;
              // this.application_Number = tokenizationVM.application_Number;
              this.amount = tokenizationVM.amount;
              this.checkout_URL = tokenizationVM.checkout_URL;
            },
            (error: HttpErrorResponse) => {
              // this.navigationExtras.state.errorCode = '';
              // this.navigationExtras.state.errorMessage = error.message;
              this.router.navigate(['/error'], {
                queryParams: {
                  errorCode: error.status,
                  errorMessage: error.error,
                },
              });
            }
          );
      }
    });

  }

}
