import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit {
  PaymentReference: string;
  Amount: string;
  Status: string;
  Currency: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.PaymentReference = params['PaymentReference'];
      this.Amount = params['Amount'];
      this.Status = params['Status'];
      this.Currency = params['Currency'];
    });
  }
}
